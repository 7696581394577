<script>
  export default {
    computed: {
        favoritesPageUrl() {
            return this.$channel.giftcardFavoritesPageUrl;
        },
        amountOfFavorites() {
            return this.$activeGiftCard?.favoriteProductIds?.length || 0;
        }
    },
  };
</script>

<template>
  <div class="favorites-header-link">
    <router-link
        :to="favoritesPageUrl"
        class="desktop-header__favorites-link"
    >
        <img 
            v-if="amountOfFavorites > 0"
            class="desktop-header__heart"
            src="../../static/icons/heart_filled.svg"
        />
        <img 
            v-if="amountOfFavorites == 0"
            class="desktop-header__heart"
            src="../../static/icons/heart.svg"
        />
        <p 
            v-if="amountOfFavorites > 0"
            class="desktop-header__favorites-amount"
        >
            {{ amountOfFavorites }}
        </p>
    </router-link>
  </div>
</template>

<style>

.favorites-header-link {
  height: 42px;
  display: flex;
}

.desktop-header__favorites-link{
  height: 100%;
  display: flex;
}

.desktop-header__heart{
  max-height: 65%;
  align-self: center;
}

.desktop-header__favorites-amount {
  position: absolute;
  margin-left: 23px;
  margin-top: 23px;
  border-radius: 50%;
  padding: 2px 5px;
  line-height: 1;
  min-width: 16px;
  text-align: center;
}

</style>
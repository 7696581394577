import { ref, inject } from 'vue';

import { activeGiftCardKey } from '@/keys';

import * as giftcard from '@/connectors/litium/giftcard';
import * as config from '@/config';

import useContext from '@/composables/useContext';
import useExecuteCommand from '@/composables/useExecuteCommand';
import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useCartTracking from '@/composables/useCartTracking';

export default function () {
  const { cart } = useContext();
  const { executeCommand } = useExecuteCommand();
  const { websiteText } = useWebsiteTexts();
  const { reportRemoveFromCart } = useCartTracking();

  const requestPending = ref(false);
  const activeGiftCard = inject(activeGiftCardKey, ref());

  async function activateGiftCard({ codes, clear }: any) {
    try {
      requestPending.value = true;

      const response = await executeCommand(
        giftcard.commands.activateGiftCard,
        {
          codes,
          clear
        }
      );

      const { error } = response;

      if (error != 'NONE') {
        throw error;
      }

      reportLoginEvent();

      return response;
    } catch (err) {
      console.error(err);
      throw websiteText('giftcard__activation_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  async function removeAllGiftCards() {
    try {
      requestPending.value = true;
      const previousRow = { ...(cart.value?.rows?.[0] || {}) };
      const response = await executeCommand(
        giftcard.commands.clearGiftCard,
        {}
      );

      reportRemoveFromCart(previousRow);

      return response;
    } catch (err) {
      console.error(err);
      throw websiteText('giftcard__activation_error').value;
    } finally {
      requestPending.value = false;
    }
  }

  async function placeRequest(body, method) {
     return await fetch(`${config.litiumBaseUrl}/api/giftcard-favorites/${method}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });
  }

  function isFavoriteProduct(productId) {
    return activeGiftCard?.value?.favoriteProductIds?.includes(productId);
  }

  function ga4FavoriteProductEvent(eventName, giftcardId, productId){
    if (typeof dataLayer === 'undefined') {
      return;
    }
    const dataToPush = {
      event: eventName,
      giftcardId: giftcardId,
      productId: productId,
    };

    dataLayer.push(dataToPush);
  }

  async function toggleFavoriteProduct(event, product){
    const giftCard = (!!activeGiftCard.value.giftCards && activeGiftCard.value.giftCards.length > 0) ? activeGiftCard.value.giftCards[0] : null;
    event.preventDefault();
    event.stopPropagation();
    const requestBody = {
      GiftCardId: giftCard.code,
      ProductId: product.articleNumber
    };
    if(isFavoriteProduct(product.articleNumber)){
      await placeRequest(requestBody, "remove-favorite");
      const i = activeGiftCard?.value?.favoriteProductIds.indexOf(product.articleNumber);
      activeGiftCard?.value?.favoriteProductIds.splice(i, 1);
      ga4FavoriteProductEvent("remove-favorite", giftCard.code, product.articleNumber);
    }else {
      await placeRequest(requestBody, "add-favorite");
      activeGiftCard?.value?.favoriteProductIds.push(product.articleNumber);
      ga4FavoriteProductEvent("add-favorite", giftCard.code, product.articleNumber);
    }
  }

  return {
    requestPending,
    activateGiftCard,
    removeAllGiftCards,
    toggleFavoriteProduct,
    activeGiftCard
  };
}

declare var dataLayer: any;

function reportLoginEvent() {
  if (typeof dataLayer === 'undefined') {
    return;
  }
  const dataToPush = {
    event: 'login'
  };

  dataLayer.push(dataToPush);
}

import { inject, ref, computed } from 'vue';
import { countrySwitcherKey } from '@/keys';

import { commands } from '@/connectors/litium/user';

import useContext from '@/composables/useContext';
import useExecuteCommand from '@/composables/useExecuteCommand';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default function () {
  const { page } = useContext();
  const { executeCommand } = useExecuteCommand();
  const { websiteText } = useWebsiteTexts();

  const countrySwitcher = inject(countrySwitcherKey, ref<any>(<any>{}));

  const availableCountries = computed(() => {
    return (countrySwitcher.value || []).map((c: any) => {
      if (c.id == '001') {
        return {
          ...c,
          id: 'EU',
        };
      }
      return c;
    });
  });

  async function changeCountry(countryId: string) {
    try {
      console.log(page.value);
      const { error, redirectUrl } = await executeCommand(commands.changeCountry, {
        countryId,
        pageId: page.value?.systemId,
        categoryId: page.value?.dataJson?.dataJson?.category?.systemId,
        productId: page.value?.dataJson?.dataJson?.product?.systemId,
      });

      if (error !== 'NONE') {
        throw websiteText('country_switcher__unknown_error').value;
      }

      return redirectUrl;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  return {
    changeCountry,
    availableCountries,
  };
}
